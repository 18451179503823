import { keyBy, merge, omit } from 'lodash';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { approve } from '../group-requests/thunks';
import { fetchCentralFeed } from '../feed/thunks';
import * as members from '../members/thunks';

import { EGroupsNamespace } from './constants';
import { groupsAdapter } from './adapter';
import * as thunks from './thunks';

import { IGroupApps, IGroupsStateExtras } from './types';

export const initialState = groupsAdapter.getInitialState<IGroupsStateExtras>({
  get: {},
  update: {},
  create: {},
  remove: {},
  membership: {},
  activity: {},
  rules: {},
  requirements: {},
  questions: {},
  notificationSettings: {},
  applications: {},
  namespaces: {
    [EGroupsNamespace.ALL]: {
      ids: [],
      meta: {},
      statuses: {},
    },
    [EGroupsNamespace.JOINED]: {
      ids: [],
      meta: {},
      statuses: {},
    },
    [EGroupsNamespace.SUGGESTED]: {
      ids: [],
      meta: {},
      statuses: {},
    },
    [EGroupsNamespace.RECOMMENDED_DIALOG]: {
      ids: [],
      meta: {},
      statuses: {},
    },
  },
});

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(members.approve.fulfilled, function (state, action) {
        const { groupId } = action.meta.arg;

        const group = groupsAdapter.getSelectors().selectById(state, groupId);
        const count = group?.membersCount as number;

        if (group) {
          groupsAdapter.updateOne(state, {
            id: groupId,
            changes: {
              membersCount: count + 1,
            },
          });
        }
      })
      .addCase(members.remove.fulfilled, function (state, action) {
        const { groupId } = action.meta.arg;

        const group = groupsAdapter.getSelectors().selectById(state, groupId);
        const count = group?.membersCount as number;

        if (group) {
          groupsAdapter.updateOne(state, {
            id: groupId,
            changes: {
              membersCount: count - 1,
            },
          });
        }
      })
      .addCase(members.addToGroup.fulfilled, function (state, action) {
        const { groupId } = action.meta.arg;
        const members = action.payload;

        const group = groupsAdapter.getSelectors().selectById(state, groupId);
        const count = group?.membersCount as number;

        if (group) {
          groupsAdapter.updateOne(state, {
            id: groupId,
            changes: {
              membersCount: count + members.length,
            },
          });
        }
      });

    builder
      .addCase(thunks.create.pending, function (state) {
        state.create = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.create.rejected, function (state) {
        state.create = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.create.fulfilled, function (state, action) {
        state.create = {
          loading: false,
          error: false,
        };

        groupsAdapter.addOne(state, action.payload);

        [
          state.namespaces[EGroupsNamespace.ALL],
          state.namespaces[EGroupsNamespace.JOINED],
        ].forEach((namespace) => {
          namespace.ids.unshift(action.payload.id as string);
        });
      });

    builder
      .addCase(thunks.query.pending, function (state, action) {
        const { offset, namespace } = action.meta.arg;
        const status = offset ? 'fetchMore' : 'fetch';

        merge(state.namespaces, {
          [namespace]: {
            statuses: {
              [status]: {
                loading: true,
                error: false,
              },
            },
          },
        });
      })
      .addCase(thunks.query.rejected, function (state, action) {
        const { offset, namespace } = action.meta.arg;
        const status = offset ? 'fetchMore' : 'fetch';

        merge(state.namespaces, {
          [namespace]: {
            statuses: {
              [status]: {
                loading: false,
                error: true,
              },
            },
          },
        });
      })
      .addCase(thunks.query.fulfilled, function (state, action) {
        const { offset, namespace } = action.meta.arg;
        const status = offset ? 'fetchMore' : 'fetch';

        merge(state.namespaces, {
          [namespace]: {
            meta: action.payload.metadata,
            statuses: {
              [status]: {
                loading: false,
                error: false,
              },
            },
          },
        });

        const ids = action.payload.groups.map((group) => group.id as string);

        state.namespaces[namespace].ids = offset
          ? state.namespaces[namespace].ids.concat(ids)
          : ids;

        groupsAdapter.upsertMany(state, action.payload.groups);
      });

    builder
      .addCase(thunks.fetchRules.pending, function (state, action) {
        const groupId = action.meta.arg;

        state.rules[groupId] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.fetchRules.rejected, function (state, action) {
        const groupId = action.meta.arg;

        state.rules[groupId] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.fetchRules.fulfilled, function (state, action) {
        const groupId = action.meta.arg;
        const data = action.payload;

        state.rules[groupId] = {
          loading: false,
          error: false,
        };

        groupsAdapter.updateOne(state, {
          id: groupId,
          changes: {
            rules: data,
          },
        });
      });

    builder
      .addCase(thunks.fetchActivity.pending, function (state, action) {
        const groupId = action.meta.arg;

        state.activity[groupId] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.fetchActivity.rejected, function (state, action) {
        const groupId = action.meta.arg;

        state.activity[groupId] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.fetchActivity.fulfilled, function (state, action) {
        const groupId = action.meta.arg;
        const data = action.payload;

        state.activity[groupId] = {
          loading: false,
          error: false,
        };

        groupsAdapter.updateOne(state, {
          id: groupId,
          changes: {
            activity: {
              ...data,
              recentActivityDate: data.recentActivityDate?.getTime(),
            },
          },
        });
      });

    builder.addCase(thunks.updateGroupInfo.fulfilled, function (state, action) {
      const { groupId } = action.meta.arg;

      state.update[groupId] = {
        loading: false,
        error: false,
      };

      groupsAdapter.updateOne(state, {
        id: groupId,
        changes: {
          ...omit(action.payload, 'slug'),
          coverImage: action.payload.coverImage || undefined,
        },
      });
    });

    builder.addCase(
      thunks.updateGroupSettings.fulfilled,
      function (state, action) {
        const { groupId } = action.meta.arg;

        state.update[groupId] = {
          loading: false,
          error: false,
        };

        const group = groupsAdapter.getSelectors().selectById(state, groupId);

        groupsAdapter.updateOne(state, {
          id: groupId,
          changes: {
            settings: {
              ...group?.settings,
              ...action.payload,
            },
          },
        });
      },
    );

    builder
      .addCase(thunks.fetchGroup.pending, (state, action) => {
        const { groupIdOrSlug } = action.meta.arg;

        state.get[groupIdOrSlug] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.fetchGroup.rejected, (state, action) => {
        const { groupIdOrSlug } = action.meta.arg;

        state.get[groupIdOrSlug] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.fetchGroup.fulfilled, function (state, action) {
        const { groupIdOrSlug } = action.meta.arg;
        const { apps, group } = action.payload;

        const applications = keyBy(apps, 'key') as IGroupApps;

        state.get[groupIdOrSlug] = {
          loading: false,
          error: false,
        };

        groupsAdapter.upsertOne(state, {
          ...group,
          applications,
        });
      });

    builder.addCase(approve.fulfilled, function (state, action) {
      const group = action.payload;

      groupsAdapter.addOne(state, group);

      [
        state.namespaces[EGroupsNamespace.ALL],
        state.namespaces[EGroupsNamespace.SUGGESTED],
      ].forEach((namespace) => {
        namespace.ids.unshift(group.id as string);
      });
    });

    builder.addCase(fetchCentralFeed.fulfilled, function (state, action) {
      const { data } = action.payload;

      groupsAdapter.setMany(state, data.groups);
    });

    builder.addCase(thunks.fetchQuestions.pending, function (state, action) {
      const groupId = action.meta.arg;

      state.questions[groupId] = {
        loading: true,
        error: false,
      };
    });

    builder.addCase(thunks.fetchQuestions.rejected, function (state, action) {
      const groupId = action.meta.arg;

      state.questions[groupId] = {
        loading: false,
        error: true,
      };
    });

    builder.addCase(thunks.fetchQuestions.fulfilled, function (state, action) {
      const groupId = action.meta.arg;

      state.questions[groupId] = {
        loading: false,
        error: false,
      };

      groupsAdapter.updateOne(state, {
        id: groupId,
        changes: {
          questions: action.payload,
        },
      });
    });

    builder.addCase(thunks.updateQuestions.pending, function (state, action) {
      const { groupId } = action.meta.arg;

      state.questions[groupId] = {
        updating: true,
        error: false,
      };
    });

    builder.addCase(thunks.updateQuestions.rejected, function (state, action) {
      const { groupId } = action.meta.arg;

      state.questions[groupId] = {
        updating: false,
        error: true,
      };
    });

    builder.addCase(thunks.updateQuestions.fulfilled, function (state, action) {
      const { groupId } = action.meta.arg;

      state.questions[groupId] = {
        updating: false,
        error: false,
      };

      groupsAdapter.updateOne(state, {
        id: groupId,
        changes: {
          questions: action.payload,
        },
      });
    });

    builder.addCase(
      thunks.fetchJoinRequirements.pending,
      function (state, action) {
        const { groupId } = action.meta.arg;

        state.requirements[groupId] = {
          loading: true,
          error: false,
        };
      },
    );

    builder.addCase(
      thunks.fetchJoinRequirements.rejected,
      function (state, action) {
        const { groupId } = action.meta.arg;

        state.requirements[groupId] = {
          loading: false,
          error: true,
        };
      },
    );

    builder.addCase(
      thunks.fetchJoinRequirements.fulfilled,
      function (state, action) {
        const { groupId } = action.meta.arg;
        const data = action.payload;

        state.requirements[groupId] = {
          loading: false,
          error: false,
        };

        groupsAdapter.updateOne(state, {
          id: groupId,
          changes: {
            requirements: data,
          },
        });
      },
    );

    builder.addCase(thunks.join.pending, function (state, action) {
      const { groupId } = action.meta.arg;

      state.membership[groupId] = {
        updating: true,
        error: false,
      };
    });

    builder.addCase(thunks.join.rejected, function (state, action) {
      const { groupId } = action.meta.arg;

      state.membership[groupId] = {
        updating: false,
        error: true,
      };
    });

    builder.addCase(thunks.join.fulfilled, function (state, action) {
      const { groupId } = action.meta.arg;

      state.membership[groupId] = {
        updating: false,
        error: false,
      };

      groupsAdapter.updateOne(state, {
        id: groupId,
        changes: action.payload,
      });

      state.namespaces[EGroupsNamespace.JOINED].ids.unshift(groupId);
      state.namespaces[EGroupsNamespace.SUGGESTED].ids = state.namespaces[
        EGroupsNamespace.SUGGESTED
      ].ids.filter((id) => id !== groupId);
    });

    builder
      .addCase(
        thunks.fetchNotificationSettings.pending,
        function (state, action) {
          const { groupId, channel } = action.meta.arg;

          if (!state.notificationSettings[groupId]) {
            state.notificationSettings[groupId] = {};
          }
          state.notificationSettings[groupId][channel!] = {
            loading: true,
            error: false,
          };
        },
      )
      .addCase(
        thunks.fetchNotificationSettings.rejected,
        function (state, action) {
          const { groupId, channel } = action.meta.arg;

          if (!state.notificationSettings[groupId]) {
            state.notificationSettings[groupId] = {};
          }
          state.notificationSettings[groupId][channel!] = {
            loading: false,
            error: true,
          };
        },
      )
      .addCase(
        thunks.fetchNotificationSettings.fulfilled,
        function (state, action) {
          const { groupId, channel } = action.meta.arg;

          if (!state.notificationSettings[groupId]) {
            state.notificationSettings[groupId] = {};
          }
          state.notificationSettings[groupId][channel!] = {
            loading: false,
            error: false,
          };

          const group = groupsAdapter.getSelectors().selectById(state, groupId);

          groupsAdapter.updateOne(state, {
            id: groupId,
            changes: {
              notificationSettings: {
                ...(group?.notificationSettings || {}),
                [channel!]: action.payload,
              },
            },
          });
        },
      );

    builder
      .addCase(
        thunks.updateNotificationSettings.pending,
        function (state, action) {
          const { groupId, channel } = action.meta.arg;

          const existedNotificationSettings =
            state.notificationSettings[groupId] || {};
          existedNotificationSettings[channel!] = {
            updating: true,
            updateError: false,
          };
        },
      )
      .addCase(
        thunks.updateNotificationSettings.rejected,
        function (state, action) {
          const { groupId, channel } = action.meta.arg;

          const existedNotificationSettings =
            state.notificationSettings[groupId] || {};
          state.notificationSettings[groupId] = {
            ...existedNotificationSettings,
            [channel!]: {
              updating: false,
              updateError: true,
            },
          };
        },
      )
      .addCase(
        thunks.updateNotificationSettings.fulfilled,
        function (state, action) {
          const { groupId, channel } = action.meta.arg;

          const existedNotificationSettings =
            state.notificationSettings[groupId] || {};
          state.notificationSettings[groupId] = {
            ...existedNotificationSettings,
            [channel!]: {
              updating: false,
              updateError: false,
            },
          };

          groupsAdapter.updateOne(state, {
            id: groupId,
            changes: {
              notificationSettings: {
                [channel!]: action.payload,
              },
            },
          });
        },
      );

    builder
      .addCase(thunks.updateGroupApps.pending, (state, action) => {
        const { groupId } = action.meta.arg;
        state.applications[groupId] = {
          updating: true,
          error: false,
        };
      })
      .addCase(thunks.updateGroupApps.rejected, function (state, action) {
        const { groupId } = action.meta.arg;

        state.applications[groupId] = {
          updating: false,
          error: true,
        };
      })
      .addCase(thunks.updateGroupApps.fulfilled, function (state, action) {
        const { groupId, apps } = action.meta.arg;
        state.applications[groupId] = {
          updating: false,
          error: false,
        };
        const appsAsMap = apps.reduce(function (res, app) {
          if (app && app.key) {
            res[app.key] = app;
          }
          return res;
        }, {} as IGroupApps);

        const group = groupsAdapter.getSelectors().selectById(state, groupId);

        groupsAdapter.updateOne(state, {
          id: groupId,
          changes: {
            applications: {
              ...(group?.applications! || {}),
              ...appsAsMap,
            },
          },
        });
      });

    builder
      .addCase(thunks.remove.pending, function (state, action) {
        const groupId = action.meta.arg;

        state.remove[groupId] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.remove.rejected, function (state, action) {
        const groupId = action.meta.arg;

        state.remove[groupId] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.remove.fulfilled, function (state, action) {
        const groupId = action.meta.arg;

        state.remove[groupId] = {
          loading: false,
          error: false,
        };

        for (const [, namespace] of Object.entries(state.namespaces)) {
          namespace.ids = namespace.ids.filter((id) => id !== groupId);
        }

        groupsAdapter.removeOne(state, groupId);
      });

    builder.addMatcher(
      isAnyOf(thunks.leave.pending, thunks.cancelRequest.pending),
      function (state, action) {
        const groupId = action.meta.arg;

        state.membership[groupId] = {
          updating: true,
          error: false,
        };
      },
    );

    builder.addMatcher(
      isAnyOf(thunks.leave.rejected, thunks.cancelRequest.rejected),
      function (state, action) {
        const groupId = action.meta.arg;

        state.membership[groupId] = {
          updating: false,
          error: true,
        };
      },
    );

    builder.addMatcher(
      isAnyOf(thunks.leave.fulfilled, thunks.cancelRequest.fulfilled),
      function (state, action) {
        const groupId = action.meta.arg;

        state.membership[groupId] = {
          updating: false,
          error: false,
        };

        groupsAdapter.updateOne(state, {
          id: groupId,
          changes: action.payload,
        });
      },
    );

    builder.addMatcher(
      isAnyOf(
        thunks.updateGroupInfo.pending,
        thunks.updateGroupSettings.pending,
      ),
      function (state, action) {
        const { groupId } = action.meta.arg;

        state.update[groupId] = {
          loading: true,
          error: false,
        };
      },
    );

    builder.addMatcher(
      isAnyOf(
        thunks.updateGroupInfo.rejected,
        thunks.updateGroupSettings.rejected,
      ),
      function (state, action) {
        const { groupId } = action.meta.arg;

        state.update[groupId] = {
          loading: false,
          error: true,
        };
      },
    );
  },
});
